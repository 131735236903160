<script>
import Layout from "../../layouts/horizontal";
import Swal from "sweetalert2";
import PageHeader from "@/components/page-header";
import http from '@/oauth-client'
import moment from 'moment'

export default {
  components: {
    Layout,
    PageHeader
  },
  props: {
    userid: {
      type: String
    }
  },
  data() {
    
    return {
      loading: true,
      processing: false,
      items: [],
      startDate: null,
      endDate: null,
      itemData: {

      },
      solutions: [
        { name: 'Clustering Risk Parity', title: 'Clustering Risk Parity'},
        { name: 'Constrain Optimal Combination', title: 'Mean-Variance'},
        { name: 'Mixed-Integer Optimization', title: 'Mixed Integer Optimization'},
        { name: 'MultiConstraint Mean Variance Optimisation', title: 'MultiConstraint Mean Variance Optimisation'},
        { name: 'MultiConstraint Tactical Asset Allocation', title: 'MultiConstraint Tactical Asset Allocation'},
        { name: 'MultiConstraint Tracking Solution', title: 'MultiConstraint Tracking Solution'},
        { name: 'Factor Model', title: 'Performance Factor Portfolio'},
        { name: 'Eigen Portfolio', title: 'Principal Components Portfolio'},
        { name: 'VolatilityEqualisation', title: 'Risk Parity'},
        { name: 'Constrained Tactical Asset Allocation', title: 'Tactical-Asset-Allocation'},
        { name: 'Weights', title: 'User Defined Weights'}
      ],
      showmodal: false,
      breadcrumbs: [
        
        {
          text: 'Predefined Risk Profiles',
          active: true
        }
      ]
    };
  },
  created() {
    this.load();
  },
  computed: {
    isBusy (){
      return this.loading;
    }
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const resp = await http.get('api/predefined-risk-profiles');
        this.items = resp.data;
      } finally {
        this.loading = false;
      }
    
    },
    onNew() {
      this.showmodal = true;
      this.itemData = {
        name: '',
        solutionName: this.solutions[0].name,
        dataJson: '',
        indicesJson: '',
        startDate: null,
        endDate: null
      }
    },
     onEdit(item) {
      this.showmodal = true;
      this.itemData = {
        id: item.id,
        name: item.name,
        solutionName: item.solutionName,
        dataJson: !item.solutionData ? '{}' : JSON.stringify(item.solutionData),
        indicesJson: !item.indices ? '[]' : JSON.stringify(item.indices),
        startDate: item.startDate ? moment(item.startDate).format("YYYY-MM-DD") : null,
        endDate: item.endDate ? moment(item.endDate).format("YYYY-MM-DD") : null
      }
    },
    async onSave() {
      if(this.itemData.name) {
        this.processing = true;
        try {
          const dto = {
            name: this.itemData.name,
            solutionName: this.itemData.solutionName,
            data: this.itemData.dataJson ? JSON.parse(this.itemData.dataJson) : {},
            indices: this.itemData.indicesJson ? JSON.parse(this.itemData.indicesJson) : [],
            startDate: this.itemData.startDate,
            endDate: this.itemData.endDate
          };
          if(this.itemData.id) {
            await http.put('api/predefined-risk-profiles/' + this.itemData.id, dto);
          } else {
            await http.post('api/predefined-risk-profiles', dto);
          }
          
          this.hideModal();
          this.load();
        } finally {
          this.processing = false;
        }
      }
    },
    hideModal() {
      this.showmodal = false;
    },
   
    tryToDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(async result  =>  {
        if (result.value) {
          this.processing = item.id;
          await http.delete('api/predefined-risk-profiles/' +item.id);
          this.processing = false;
          this.load();
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Predefined Risk Profiles" :items="breadcrumbs" :is-busy="isBusy"/>

    <b-modal
      v-model="showmodal"
      title="Risk Profile"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">Name [*]</label>
          <input
            id="name"
            v-model="itemData.name"
            type="text"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="solution">Solution</label>
          <select
            id="solution"
            v-model="itemData.solutionName"
            class="form-control"
          >
            <option v-for="s in solutions" :key="s.name" :value="s.name">{{ s.title }}</option>
          </select>
          
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="solution">Start date</label>
              <input  
                type="date"
                v-model="itemData.startDate"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="solution">End date</label>
              <input  
                type="date"
                v-model="itemData.endDate"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="solution">Data (JSON)</label>
          <textarea class="form-control"  v-model="itemData.dataJson" rows="10">

          </textarea>
          
        </div>
        <div class="form-group">
          <label for="solution">Indices (JSON)</label>
          <textarea class="form-control"  v-model="itemData.indicesJson" rows="10">

          </textarea>
          
        </div>
        <div>
          <b-button class="ml-1 float-left" @click="hideModal"> Close</b-button>
          <button type="button" @click="onSave" class="btn btn-success float-right" :disabled="!itemData.name || processing">
            <i class="fa fa-spin fa-spinner" v-if="processing"></i>
           Save
          </button>
        </div>
      </form>
    </b-modal>
    <div class="card p-0 cardc8">
      <div class="card-content m-3">
        <button class="btn btn-link float-right p-2" @click="onNew">
          <i class="fa fa-plus mr-2 mb-2"/>  Add New Profile
        </button>
        <div class="table-responsive">
          <table class="table  table-borderless nowrap">
            <thead>
              <tr>
                <th style="width:60px; max-width:60px"></th>
                <th>Name</th>
                <th>Solution Name</th>
                <th style="width:60px; max-width:60px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!loading && items.length === 0">
                <td colspan="100">
                  <span class="text-muted">No records found....</span>
                </td>
              </tr>
              <tr v-for="item in items" :key="item.id">
                <td style="width:60px; max-width:60px">
                  <a href="javascript:void(0)" class="py-2 px-2" @click="onEdit(item)">
                    <i class="fa fa-edit"></i>
                  </a>
                </td>
                <td >
                  <span class="py-2">

                    {{ item.name }}
                  </span>
                </td>
                <td >
                  <span class="py-2" v-if="item.solutionName">

                    {{ solutions.find(x => x.name === item.solutionName).title }}
                  </span>
                </td>
                <td style="width:60px; max-width:60px">
                  <a href="javascript:void(0)" class="py-2 px-2 text-danger" @click="tryToDelete(item)" :disabled="processing">
                    <i v-if="processing !== item.id" class="fa fa-trash"></i>
                    <i v-if="processing === item.id"  class="fa fa-spin fa-spinner"></i>
                  </a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
 </Layout>
</template>